import React from 'react'
import { Anchor, Divider } from 'antd'
import Container from '../components/Landing/Container'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'

const { Link } = Anchor

export default function RevisorTeamGuidePage({ setCookieValue, data }) {
    const affixContainerStyle: React.CSSProperties = isMobile
        ? {}
        : { display: 'grid', gridTemplateColumns: '1fr 6fr 1fr', marginLeft: 10, marginRight: 10 }

    return (
        <Container setCookieValue={setCookieValue} pathName="revisor-team">
            <SEO title="Rådgiver Guide" />
            <span style={affixContainerStyle}>
                {!isMobile && (
                    <Anchor offsetTop={100} targetOffset={156}>
                        <Link href="#guide-title" title="Tilføj Revisor">
                            <Link href="#step-1" title="1. Opret skatteår" />
                            <Link href="#step-2" title="2. Vælg revisor" />
                            <Link href="#step-3" title="3. Anmod" />
                            <Link href="#step-4" title="4. Afvent" />
                        </Link>
                    </Anchor>
                )}
                <div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 100,
                        }}
                        id="guide-title"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            Team op med din revisor
                        </h1>
                        <p className="landing-guide-header-p">
                            Giv din revisor adgang via platformen og gør det let at samarbejde eller
                            helt udlicitere arbejdet til revisoren. Når et skatteår skal lignes af
                            Skattestyrelsen kan du give din revisor adgang til din data for det
                            pågældende skatteår. Herfra kan revisoren færdiggøre udarbejdelsen af
                            skatterapporterne og aflevere dem til Skattestyrelsen.
                        </p>
                    </div>
                    <Divider />
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-1"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>1. Opret skatteår</h1>
                            <p style={{ maxWidth: 400 }}>Start med at oprette et skatteår.</p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 1"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration-big"
                            fluid={data.s1.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-2"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>2. Vælg revisor</h1>
                            <p style={{ maxWidth: 400 }}>
                                Derefter gå til "Overview" for skatteåret og tryk "Request Advisor".
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 2"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration-big"
                            fluid={data.s2.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-3"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>3. Anmod revisor</h1>
                            <p style={{ maxWidth: 400 }}>
                                Find din revisor på listen og tryk send anmodning. Du kan vælge hvor
                                meget adgang revisoren skal have til din data, men vær opmærksom på
                                at hvis du ikke giver ham fuld adgang ("View, edit & download") er
                                det begrænset hvor meget han kan hjæple dig.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 3"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration-big"
                            fluid={data.s3.childImageSharp.fluid}
                        />
                    </div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            justifyContent: 'center',
                            flexWrap: 'wrap',
                        }}
                        id="step-4"
                    >
                        <div style={{ width: 500 }}>
                            <h1 style={{ fontSize: 30 }}>4. Afvent Revisor</h1>
                            <p style={{ maxWidth: 400 }}>
                                Når du har anmodet din revisor kan du se det under "Overview" for
                                det pågældende skatteår. Det er også her du kan se om han har
                                godkendt din forespørgsel om at revidere dit skatteår.
                            </p>
                        </div>
                        <Img
                            title="Guide Image"
                            alt="step 4"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration-big"
                            fluid={data.s4.childImageSharp.fluid}
                        />
                    </div>
                </div>
            </span>
        </Container>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "guide-assets/revisor-team-step-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s2: file(relativePath: { eq: "guide-assets/revisor-team-step-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s3: file(relativePath: { eq: "guide-assets/revisor-team-step-3.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s4: file(relativePath: { eq: "guide-assets/revisor-team-step-4.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`
